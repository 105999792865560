/**
* Custom secret schema
**/
.rjsf {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  
    .col-xs-offset-9 {
      margin-left: 75%;
    }
  
    .col-xs-3 {
      flex: 0 0 25%;
      max-width: 25%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
  
    .col-xs-9 {
      flex: 0 0 100%;
      max-width: 100%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
  
    .array-item-list {
      display: block!important;
      margin: 0px!important;
    }
  
    .array-item {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 20px 0!important;
      border: 1px #ffa538 solid;
      border-radius: 5px;
      padding: 15px;
    }
  
    .btn-group {
      margin-top: 64px;
    }
  
  }


  #responseWrapperView {
    padding:10px;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
/*************************************************************
    Default variables:
    @import "black-dashboard-pro-react/custom/variables";

                       -------------
                       |  WARNING  |
                       -------------
BEFORE PUSHING CHANGES THIS VARIABLES IMPORT NEEDSS TO BE SET 
BACK TO:
            @import "../../envs/arcare/variables";
**************************************************************/
@import "../../envs/arcare/style";
/**************************************************************/